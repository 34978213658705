const domain = "https://api.evelyniuliano.com"

export default {
    domain,
    authorName: 'Evelyn Iuliano',
    authorMail: 'press@evelyniuliano.com',
    api: domain + "/wp-json/wp/v2",
    auth: domain + "jwt-auth/v1",
    debug: true,
    languages: ['it', 'en']
}