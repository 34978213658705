import * as Post from "./Post"
import * as Auth from "./Auth"
import * as General from "./General"
import * as Page from "./Page"

export default {
    Post,
    Auth,
    General,
    Page
}