import React, { useEffect, useState, useGlobal } from 'reactn'

// Third-party components
import { Row, Col } from 'antd'

// Custom components
import SectionTitle from '../Components/SectionTitle'

// Libraries
import API from '../API'
import Featured from '../Components/Featured'

type PageType = {
    ID: number,
    featured: string,
    postTitle: string,
    postContent: string,
    meta: any
}

const Page = ({
    ID,
    featured,
    postTitle,
    postContent,
    meta
}: PageType) => {

    /**** STATE VARIABLES ****/
	const [posts, setPosts] = useState<any[]>([])

	/**** BOOT ****/
	useEffect(() => {
		
	}, [])

    return (
		<div style={{ marginBottom: 100 }}>
            <Row>
                <Col
                    span={20}
                    offset={2}
                    style={{ display: 'flex', justifyContent: 'center' }}
                >
                    <SectionTitle title={postTitle}/>
                </Col>
            </Row>
            <Row>
                <Col
                    span={20}
                    offset={2}
                >
                    <span>
                        {
                            featured
                            ?
                            <img src={featured} alt={postTitle} className="bio-pic"/>
                            :
                            null
                        }
			            <div dangerouslySetInnerHTML={{ __html: postContent }}/>
                    </span>
                </Col>
            </Row>
		</div>
	)

}

export default Page