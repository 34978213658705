import React, { useEffect, useState } from 'reactn'

// Third-party components
import CookieConsent from 'react-cookie-consent'

// Custom components
import Menu from './Menu'
import Footer from './Footer'

// Libraries
import { Outlet, useRouteError } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import useColors from '../Config/Colors'

/**
 * Root element for React Router
 * @param props Props:
 *  items: Menu elements to pass to Menu component
 * @returns {React.Component}
 */
const Root = (
    {items}: any
) => {

    /**** STATE VARIABLES ****/
    const [ready, setReady] = useState<boolean>(false)
    
    /**** HOOKS ****/
    const error = useRouteError()
    const { t } = useTranslation()
    const colors = useColors()

    useEffect(() => {
        if(colors){
            setReady(true)
        }
    }, [colors])

    return(
        <>
            <Menu items={items}/>
            <div id="detail">
                <Outlet/>
            </div>
            <Footer/>
            {
                ready
                ?
                <CookieConsent
                    buttonText={t('hoCapito')}
                    cookieName='consentCookie'
                    style={{
                        backgroundColor: colors.base,
                        color: colors.text
                    }}
                    buttonStyle={{
                        borderRadius: 7,
                        backgroundColor: colors.secondary,
                        color: colors.text
                    }}
                >
                    {t('infoCookie')}
                </CookieConsent>
                :
                null
            }
        </>
    )
}

export default Root